import React, { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi2";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Feedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location?.state);
  const [data] = useState(location?.state.data);
  const [firstname] = useState(data?.firstname);
  const [email] = useState(data?.email);
  //   const datas = { data: { email: "adepojujohn56@gmail.com" }, message: "" };
  //   const { data, message } = datas;

  useEffect(() => {
    if (location?.state?.data == null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {data != null && (
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-32 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
              <HiCheckCircle className="mb-4 mx-auto text-7xl tracking-tight font-extrabold lg:text-9xl text-success2 dark:text-success2" />
              <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                Thanks for your Payment!
              </p>
              <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                Dear {firstname}, we have received your payment successfully.
                Please kindly check your email ({email}) for you ticket QRCode.
              </p>
              <div className="flex items-center space-x-4 justify-center">
                <Link
                  to={`/`}
                  className="inline-flex py-4 text-white bg-secondary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-secondary font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-secondary my-4"
                >
                  Back to Home
                </Link>
                <Link
                  to={`/details/${data?.event_id}`}
                  className="inline-flex py-4 text-white bg-blue hover:bg-blue focus:ring-4 focus:outline-none focus:ring-blue font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue my-4"
                >
                  Buy Ticket Again
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Feedback;
