import React from 'react';
import { moloyalpix } from '../../assets';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl text-primary font-semibold leading-none font-poppins ">
            Leading Digital Agency for Business Solution
          </h1>
          <p className="text-xl lg:text-xl text-justify text-black3 mt-6 font-light font-poppins ">
            MoLoyal Consulting Solutions is a part of the Avante Consulting
            group of companies that manufactures software and provides
            technology services to the industries including Financial Services,
            Public Sector, Telecommunications, Energy, Retail, Education and
            Consumer Goods. We leverage on our deep IT industry insight to
            introduce cutting edge technology solutions and offer consulting to
            leading organisations and institutions.
          </p>
          <p className="mt-8 md:mt-12">
            <Link to="/contact">
              <button
                type="button"
                className="py-4 px-12 bg-secondary rounded text-white"
              >
                Learn more
              </button>
            </Link>
          </p>
        </div>
        <div className="lg:w-1/2 lg:mt-0 mt-8 lg:ml-24 md:ml-32 ml-0">
          <img src={moloyalpix} alt="" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
