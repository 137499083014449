import React, { useState } from "react";
import { close, logo, menu } from "../assets";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Product from "../components/Product";

const Navbar = () => {
  // const { cartTotalQuantity } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  // console.log(cartTotalQuantity);

  const cartTotalQuantity = cart.cartItems.length;
  const [toggle, setToggle] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <nav className="fixed inset-x-0 top-0 z-50   flex justify-between  border-b bg-white   items-start w-full mx-auto py-4 ">
      <Link to="/">
        {" "}
        <div>
          <img src={logo} alt="logo" className="md:w-full w-[100px] md:ml-4" />
        </div>
      </Link>
      <div
        id="navbar-sticky"
        className="md:static absolute bg-background md:min-h-fit min-h-[60vh] left-0 md:top-[-100%] md:block hidden md:w-auto w-full flex items-center px-5 py-4"
      >
        <ul className=" flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 ml-4 ">
          <li>
            <Link
              to="/"
              className=" hover:text-secondary lg:text-[16px] md:text-[10px]"
            >
              Events
            </Link>
          </li>
          {/* <li>
            <a
              className=" hover:text-secondary lg:text-[16px] md:text-[10px]"
              href="/about"
            >
              About Us
            </a>
          </li> */}
          <li>
            <a className=" hover:text-secondary lg:text-[16px]  md:text-[10px]">
              <Product />
            </a>
          </li>

          <li>
            <a
              className=" hover:text-secondary lg:text-[16px] md:text-[10px] "
              href="/contact"
            >
              Contact us
            </a>
          </li>
          <li>
            <a className=" hover:text-secondary lg:text-[16px] md:text-[10px]"></a>
            <div>
              <Link to="/cart">
                {" "}
                <button
                  type="button"
                  className="relative items-center rounded bg-blue-600 px-6 py-2.5 lg:text-[16px] md:text-[10px]    text-black2 transition duration-150 ease-in-out hover:text-secondary mr-8 focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                >
                  Cart
                  <span className="absolute top-0 right-0 -mt-2 -mr-2 flex h-6 w-6 rounded-lg bg-secondary">
                    <span className="absolute top-0 right-0 left-0 bottom-0 animate-ping rounded-lg bg-secondary opacity-75"></span>
                    <span className="px-2 -py-1.5 align-baseline font-bold text-white">
                      {cartTotalQuantity}
                    </span>
                  </span>
                </button>
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <div className=" flex  items-center md:block hidden gap-6 md:py-4 mx-4 ">
        <Link to="/create">
          <button className="bg-secondary text-background lg:text-[14px] md:text-[10px] px-5  py-2 rounded-full">
            Create event{" "}
          </button>
        </Link>
        <Link to="/login">
          <button className="bg-primary text-background px-5  py-2 rounded-full md:ml-4">
            Sign In{" "}
          </button>
        </Link>
      </div>
      <div className="md:hidden   top-0   flex flex-1 justify-end w-full h-full  mx-4   items-center bg-background ">
        <img
          src={`${toggle && !showMobileNav ? close : menu} `}
          className={` w-[28px] h-[28px] object-contain`}
          onClick={() => {
            setToggle((prev) => !prev);
            setShowMobileNav(false);
          }}
          alt="menu"
        />
        <div
          className={`p-6  absolute bg-background w-full h-[1200px]   top-10 -px-2
          
 duration-500 py-24    ${toggle ? " flex right-0 " : "right-[-100%]   "}  ${
            showMobileNav ? "hidden" : ""
          } `}
        >
          <div
            className={`list-none px-3     flex-col justify-start items-start flex flex-1 -mt-12`}
          >
            <ul className=" flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-4 ">
              <li>
                <Link
                  className=" hover:text-secondary"
                  to="/"
                  onClick={() => setShowMobileNav(true)}
                >
                  Events
                </Link>
              </li>

              <li>
                <Link
                  className=" hover:text-secondary"
                  onClick={() => setShowMobileNav(true)}
                  to="/products"
                >
                  <Product />
                </Link>
              </li>
              <li>
                <Link
                  className=" hover:text-secondary"
                  onClick={() => setShowMobileNav(true)}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              <Link onClick={() => setShowMobileNav(true)} to="/cart">
                {" "}
                <button
                  type="button"
                  className="relative items-center rounded bg-blue-600 px-1 py-2.5 lg:text-[16px] md:text-[10px]    text-black2 transition duration-150 ease-in-out hover:text-secondary mr-8 focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                >
                  Cart
                  <span className="absolute top-0 right-0 -mt-2 -mr-2 flex h-6 w-6 rounded-lg bg-secondary">
                    <span className="absolute top-0 right-0 left-0 bottom-0 animate-ping rounded-lg bg-secondary opacity-75"></span>
                    <span className="px-2 -py-1.5 align-baseline font-bold text-white">
                      {cartTotalQuantity}
                    </span>
                  </span>
                </button>
              </Link>
            </ul>
          </div>
          <div className="absolute flex justify-center items-center gap-6 mt-32 md:py-4 ml-3  ">
            <Link to="/create" onClick={() => setShowMobileNav(true)}>
              <button className="bg-secondary text-background lg:text-[14px] md:text-[10px] px-5  py-2 rounded-full">
                Create event
              </button>
            </Link>
            <Link to="/login" onClick={() => setShowMobileNav(true)}>
              <button className="bg-primary text-background px-5  py-2 rounded-full md:mr-4">
                Sign In{" "}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
