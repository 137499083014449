import React, { useEffect, useState } from "react";
import { paystack } from "../assets";
import { PaystackButton, usePaystackPayment } from "react-paystack";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
// import PaymentForm from './PaymentForm';
import { Card, CardBody, Dialog, Input } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearCart } from "../feature/cartSlice";
import { HiOutlineXMark } from "react-icons/hi2";

const Payment = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const { isOpen, onClose } = useDisclosure();
  const publicKey = "pk_test_668224c43d08c0abee6cc92873c960c28ffdde66";
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const cart = useSelector((state) => state.cart);
  const amount = cart.cartTotalAmount * 100;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const today_date = yyyy + mm + dd;
  const ref = today_date + Math.floor(Math.random() * 10000 + 1);

  const ticketData = {
    firstname,
    lastname,
    email,
    phoneno,
    amount: cart?.cartTotalAmount,
    sn: cart?.cartItems[0]?.sn,
    ref,
    currency: "NGN",
    event_id: cart?.cartItems[0]?.event_id,
    channel: "Moticket Web",
    paymentChannel: "Paystack",
    myCart: cart?.cartItems,
    userconsent: 0,
  };

  const onSuccess = (result) => {
    console.log(result);
    console.log(ticketData);
    dispenseTicket();
  };
  const onPaymentClose = () => {
    alert("Wait! You need this Ticket, don't go!!!!");
  };
  const handleClearcart = () => {
    dispatch(clearCart(cart?.cartItems));
  };

  const dispenseTicket = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payments/dispense`,
        ticketData
      );
      console.log(response.data);
      setLoading(false);
      if (!response.data.error) {
        // display toast message
        const { data, message } = response.data;
        //toast.success(message);
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhoneno("");
        handleClearcart();
        setOpen();
        //navigate('/');
        navigate("/response", { state: { data } });
      } else {
        const { message } = response.data.data;
        toast.error(message);
      }
    } catch (error) {
      console.log("Error saving payment transaction:  ", error);
      toast.error("Error dispensing ticket");
    }
  };

  const componentProps = {
    email,
    amount,
    reference: ref,
    metadata: {
      name: firstname + lastname,
      phone: phoneno,
      custom_fields: [
        {
          display_name: "Customer Name",
          variable_name: "Cust_name",
          value: firstname + " " + lastname,
        },
        {
          display_name: "Email Address",
          variable_name: "email_add",
          value: email,
        },
        {
          display_name: "Phone Number",
          variable_name: "phone",
          value: phoneno,
        },
        {
          display_name: "Platform",
          variable_name: "ptf",
          value: "Moticket web",
        },
      ],
    },
    publicKey,
    text: "Pay",
    onSuccess,
    onClose: onPaymentClose,
  };

  const initializePayment = usePaystackPayment(componentProps);

  const makePayment = () => {
    initializePayment(onSuccess, onPaymentClose);
  };

  useEffect(() => {
    if (cart) {
      console.log(cart);
    }
  }, []);

  return (
    <>
      <div className="min-h-screen p-4 mt-32 flex justify-center align-center bg-gray-200 leading-loose">
        <form className="mt-32 ">
          <p className=" text-gray-800  mb-8 font-medium">Payment gateway</p>
          <Button className="   " onClick={handleOpen}>
            <label className="peer-checked:border-2 bg-white  peer-checked:border-gray-700 peer-checked:bg-white flex cursor-pointer select-none rounded-lg border border-gray-300 p-4">
              <img className="w-32 object-contain" src={paystack} alt="" />
              <div className="ml-44 mt-4 ">
                <span className="font-semibold text-primary text-[18px]">
                  Pay with Paystack
                </span>
              </div>
            </label>
          </Button>
          <Dialog
            size="md" //"xs" | "sm" | "md" | "lg" | "xl" | "xxl";
            open={open}
            handler={handleOpen}
            className="bg-transparent shadow-none"
          >
            <Card className="mx-auto w-full max-w-[24rem]">
              <div className="flex items-center justify-center p-6">
                Payment Form
              </div>
              <button
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={handleOpen}
              >
                <HiOutlineXMark className="w-5 h-5" />
                <span className="sr-only">Close modal</span>
              </button>

              <CardBody className="flex flex-col gap-4">
                <Input
                  label="First Name"
                  size="lg"
                  id="firstname"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  label="Last Name"
                  size="lg"
                  id="lastname"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <Input
                  label="Email"
                  size="lg"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Phone"
                  size="lg"
                  id="phoneno"
                  value={phoneno}
                  onChange={(e) => setPhoneno(e.target.value)}
                />
                <p className="">Amount: ₦{amount / 100}</p>
                {/* <PaystackButton
                  className="paystack-button py-2 px-6 bg-secondary text-background rounded-lg"
                  {...componentProps}
                /> */}
                <button
                  onClick={makePayment}
                  className="paystack-button py-2 px-6 bg-secondary text-background rounded-lg"
                >
                  {loading ? "Processing..." : "Pay"}
                </button>
              </CardBody>
            </Card>
          </Dialog>
        </form>
      </div>
      {/* <ChakraModal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Forms</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <PaymentForm /> 
          </ModalBody>
        </ModalContent>
      </ChakraModal> */}
    </>
  );
};

export default Payment;
