import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateEventForm = () => {
  const [step, setStep] = useState(1);
  const [eventData, setEventData] = useState({
    title: '',
    convenerName: '',
    selectedState: '',
    venue: '',
    image: null,
    startTime: '',
    endTime: '',
    startDate: '',
    endDate: '',
    convenerPhoto: null,
    description: '',
    categories: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleImageChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.files[0] });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleDescriptionChange = (description) => {
    setEventData({ ...eventData, description });
  };

  const handleAddCategory = () => {
    const newCategory = {
      name: '',
      price: '',
      discountPrice: '',
      walletDiscount: '',
      quantity: '',
    };
    setEventData({
      ...eventData,
      categories: [...eventData.categories, newCategory],
    });
  };

  const handleCategoryChange = (index, field, value) => {
    const updatedCategories = [...eventData.categories];
    updatedCategories[index][field] = value;
    setEventData({ ...eventData, categories: updatedCategories });
  };

  const handleDeleteCategory = (index) => {
    const updatedCategories = [...eventData.categories];
    updatedCategories.splice(index, 1);
    setEventData({ ...eventData, categories: updatedCategories });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can submit the form data here or perform any desired actions
    console.log(eventData);
  };

  // List of Nigerian states
  const nigerianStates = [
    'Abia',
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Federal Capital Territory',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
  ];

  return (
    <div className="min-h-screen flex items-center mt-32 justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg w-4/5 lg:w-3/5">
        <h2 className="text-2xl font-semibold mb-4">Create Event</h2>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              {/* Step 1: Event Details */}
              <div className="mb-4">
                <label htmlFor="title" className="block text-gray-600">
                  Title:
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="convenerName" className="block text-gray-600">
                  Convener's Name:
                </label>
                <input
                  type="text"
                  id="convenerName"
                  name="convenerName"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.convenerName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="selectedState" className="block text-gray-600">
                  Select State:
                </label>
                <select
                  id="selectedState"
                  name="selectedState"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.selectedState}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Select State --</option>
                  {nigerianStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="venue" className="block text-gray-600">
                  Venue:
                </label>
                <input
                  type="text"
                  id="venue"
                  name="venue"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.venue}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-600">
                  Event Image:
                </label>
                <div
                  className="w-full h-40 border-dashed border-2 border-gray-300 flex flex-col items-center justify-center cursor-pointer"
                  onDrop={handleImageChange}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {eventData.image ? (
                    <img
                      src={URL.createObjectURL(eventData.image)}
                      alt="events"
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <p>Drag & drop an image here or click to select</p>
                  )}
                </div>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                  required
                />
              </div>{' '}
            </>
          )}
          {step === 2 && (
            <>
              {/* Step 2: Event Time and Convener Photo */}
              <div className="mb-4">
                <label htmlFor="startTime" className="block text-gray-600">
                  Start Time:
                </label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.startTime}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="endTime" className="block text-gray-600">
                  End Time:
                </label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.endTime}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="startDate" className="block text-gray-600">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.startDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="endDate" className="block text-gray-600">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  value={eventData.endDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="convenerPhoto" className="block text-gray-600">
                  Convener Photo:
                </label>
                <div
                  className="w-full h-40 border-dashed border-2 border-gray-300 flex flex-col items-center justify-center cursor-pointer"
                  onDrop={(e) => {
                    e.preventDefault();
                    handleImageChange({
                      target: {
                        name: 'convenerPhoto',
                        files: e.dataTransfer.files,
                      },
                    });
                  }}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {eventData.convenerPhoto ? (
                    <img
                      src={URL.createObjectURL(eventData.convenerPhoto)}
                      alt="Convener "
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <p>Drag & drop a photo here or click to select</p>
                  )}
                </div>
                <input
                  type="file"
                  id="convenerPhoto"
                  name="convenerPhoto"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                  required
                />
              </div>{' '}
            </>
          )}
          {step === 3 && (
            <>
              {/* Step 3: Description */}
              <div className="mb-4">
                <label htmlFor="description" className="block text-gray-600">
                  Description:
                </label>
                <ReactQuill
                  id="description"
                  name="description"
                  value={eventData.description}
                  onChange={handleDescriptionChange}
                  className="h-60 px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
            </>
          )}
          {step === 4 && (
            <>
              {/* Step 4: Categories */}
              <h3 className="text-xl font-semibold mb-4">Categories</h3>
              <table className="w-full mb-4">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Price</th>
                    <th className="px-4 py-2">Discount Price</th>
                    <th className="px-4 py-2">Wallet Discount</th>
                    <th className="px-4 py-2">Quantity</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {eventData.categories.map((category, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          name="name"
                          value={category.name}
                          onChange={(e) =>
                            handleCategoryChange(index, 'name', e.target.value)
                          }
                          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="price"
                          value={category.price}
                          onChange={(e) =>
                            handleCategoryChange(index, 'price', e.target.value)
                          }
                          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="discountPrice"
                          value={category.discountPrice}
                          onChange={(e) =>
                            handleCategoryChange(
                              index,
                              'discountPrice',
                              e.target.value
                            )
                          }
                          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="walletDiscount"
                          value={category.walletDiscount}
                          onChange={(e) =>
                            handleCategoryChange(
                              index,
                              'walletDiscount',
                              e.target.value
                            )
                          }
                          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="quantity"
                          value={category.quantity}
                          onChange={(e) =>
                            handleCategoryChange(
                              index,
                              'quantity',
                              e.target.value
                            )
                          }
                          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleDeleteCategory(index)}
                          className="bg-secondary ml-1 hover:bg-red-600 text-white px-2 py-1 rounded-md focus:outline-none"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="bg-primary hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none"
                onClick={handleAddCategory}
              >
                Add Category
              </button>
            </>
          )}
          <div className="flex justify-between">
            {step > 1 && (
              <button
                type="button"
                className="bg-secondary mt-8 hover:bg-gray-400 text-background px-4 py-2 rounded-md focus:outline-none"
                onClick={handlePreviousStep}
              >
                Previous Step
              </button>
            )}
            {step < 4 && (
              <button
                type="button"
                className="bg-primary hover:bg-blue-600 mt-8 text-white px-4 py-2 rounded-md focus:outline-none"
                onClick={handleNextStep}
              >
                Next Step
              </button>
            )}
            {step === 4 && (
              <button
                type="submit"
                className="bg-primary hover:bg-blue-600 mt-8 text-white px-4 py-2 rounded-md focus:outline-none"
              >
                Create Event
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventForm;
