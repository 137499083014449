import { Popover, Transition } from '@headlessui/react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const solutions = [
  {
    name: 'Loyalty',
    href: 'https://moloyal.com/loyalty',
  },
  {
    name: 'Mosave',
    href: 'https://moloyal.com/mosave2',
  },
  {
    name: 'API',
    href: 'https://moloyal.com/api.php#savings',
  },
];

const timeoutDuration = 120;

export default function Example() {
  const location = useLocation();

  const triggerRef = useRef();
  const timeOutRef = useRef();

  const handleEnter = (isOpen) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  useEffect(() => {}, [location]);

  return (
    <div className="w-full">
      <Popover className="relative">
        {({ open }) => (
          <>
            <div
              onMouseEnter={() => handleEnter(open)}
              onMouseLeave={() => handleLeave(open)}
            >
              <Popover.Button
                className="group focus:outline-none"
                ref={triggerRef}
                aria-expanded="true"
                aria-haspopup="true"
              >
                <span>Products</span>
              </Popover.Button>
              <Transition>
                <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-4xl">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white p-7">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex flex-col p-2 transition duration-150 ease-in-out hover:text-white hover:bg-primary focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => {
                            triggerRef.current?.click(); // Close the popover
                          }}
                        >
                          <p className="text-[18px] hover:text-white font-medium py-2 text-gray-900">
                            {item.name}
                          </p>
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          </>
        )}
      </Popover>
    </div>
  );
}
