import React from 'react';

const FrequentlyAsked = () => {
  return (
    <div>
      <section className="text-gray-700">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
              Frequently Asked Question
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              The most common questions about how our business works and what
              can do for you.
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  What are paid events?
                </summary>

                <span>Events guests have to buy tickets for attendance.</span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                  How do i register my event?{' '}
                </summary>

                <span>
                  Upload your event details on the form to register your event.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  What are Access Control/Free Events ?{' '}
                </summary>

                <span>
                  Events that requires no payment but registrations for control
                  of access.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  How will I recieve payments for my Event tickets purchased ?{' '}
                </summary>

                <span className="px-4 py-2">
                  Payments are automated and sent directly to your bank account.
                  You will receive 90% of the ticket value and 10% goes to
                  MoLoyal.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  How do I recieve my tickets after purchase?{' '}
                </summary>

                <span className="px-4 py-2">
                  You will receive an email immediately after purchase. Please
                  check your spam inbox too.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  What other benefits do I get by registering my event on
                  Moloyal?{' '}
                </summary>

                <span className="px-4 py-2">
                  Customers who buy MoLoyal tickets get loyalty points which
                  incentivises them to make more ticket purchases in the future.
                </span>
              </details>
            </div>
            <div className="w-full lg:w-1/2 px-4 py-2">
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  How do I become a Moloyal Customer and earn Loyalty Points?{' '}
                </summary>

                <span className="px-4 py-2">
                  Buy any MoLoyal Ticket and register by agreeing to the terms
                  and conditions. You will receive default customer login
                  details via email. Log in to your account to track Loyalty
                  points.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  What are the event categories available ?{' '}
                </summary>

                <span className="px-4 py-2">
                  Categories include Festivals, Hangout, Party, Comedy ,Fashion
                  and more.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  What other service do i get by registering my events ?{' '}
                </summary>

                <span className="px-4 py-2">
                  Get 10% discount from our live band partner.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  How do I get assistance with Ticket validation at my Venue{' '}
                </summary>

                <span className="px-4 py-2">
                  You can hire MoLoyal tickets agents at a fee for this.
                  Indicate your interest while filling the registration form.
                </span>
              </details>
              <details className="mb-4">
                <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                  How do I validate Tickets QR code for my event ?{' '}
                </summary>

                <span className="px-4 py-2">
                  Log in to MoLoyal agent app and scan QR code to validate
                  ticket.
                </span>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FrequentlyAsked;
