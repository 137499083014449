import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./layouts/Footer";
import MainPage from "./layouts/MainPage";
import Navbar from "./layouts/Navbar";
import EventDetails from "./components/EventDetails";
// import ProccedToCheckout from './components/ProccedToCheckout';
import PaymentForm from "./payment/PaymentForm";
import Card from "./card/Card";
import LoginForm from "./auth/Login";
import Register from "./auth/Register";
import ContactUs from "./components/ContactUs";
import AboutBanner from "./layouts/about/AboutBanner";
import { ToastContainer } from "react-toastify";
import Cart from "./components/Cart";
import Payment from "./components/Payment";
import Feedback from "./components/Feedback";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/details/:id" element={<EventDetails />} />
        {/* <Route path="/cart" element={<ProccedToCheckout />} /> */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/payment" element={<PaymentForm />} />
        <Route path="/create" element={<Card />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutBanner />} />
        <Route path="/payments" element={<Payment />} />
        <Route path="/response" element={<Feedback />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
