import React from 'react';
import AboutUs from './AboutUs';
import Vision from '../../components/Vision';

const AboutBanner = () => {
  return (
    <div>
      <AboutUs />
      <Vision />
    </div>
  );
};

export default AboutBanner;
