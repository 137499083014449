import React from "react";
import ImageSlider from "../components/ImageSlider";
import CardSection from "../components/CardSection";
import Advert from "../components/Advert";
// import Patners from '../components/Patners';
import Filters from "../components/Filters";
import FrequentlyAsked from "../components/FrequentlyAsked";
import Howitworks from "../components/Howitworks";

const MainPage = () => {
  return (
    <div>
      <ImageSlider />
      <Filters />
      <CardSection />
      <Advert />
      <FrequentlyAsked />
      <Howitworks />
      {/* <Patners /> */}
    </div>
  );
};

export default MainPage;
