import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: {
    data: [],
  },
  status: null,
};

export const eventsFetch = createAsyncThunk("events/eventsFetch", async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/tickets`);
  console.log(response);
  return response?.data;
});

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: {
    [eventsFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [eventsFetch.fulfilled]: (state, action) => {
      state.status = "success";
      state.items = action.payload;
    },
    [eventsFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default eventSlice.reducer;
