import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotal,
  removeFromCart,
} from "../feature/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { card5 } from "../assets";

const imageBaseUrl =
  "https://moloyal.com/test/mosave/adminscript/api/ticket_images/";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const events = useSelector((state) => state.events);
  const dispatch = useDispatch();
  console.log(cart);
  console.log(events);
  const navigate = useNavigate();

  const [filteredEvent, setFilteredEvent] = useState({});

  useEffect(() => {
    dispatch(getTotal());
  }, [cart, dispatch]);

  // useEffect(() => {
  //   if (cart) {
  //     const id = cart.cartItems[0].event_id;

  //     console.log(id);
  //     getEvent(id);
  //   }
  // }, [cart]);

  const handleRemoveFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
  };

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
  };

  const handleIncreasedQuantity = (cartItem) => {
    dispatch(addToCart(cartItem));
  };
  const handleClearcart = (cartItem) => {
    dispatch(clearCart(cartItem));
  };

  const getEvent = (eventId) => {
    const newEvent = events.items.data.filter(
      (event) => event.sn == eventId
    )[0];
    console.log(newEvent);
    return newEvent;
    // setFilteredEvent(newEvent);
  };

  return (
    <div className="bg-gray-100 h-screen mt-44 py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-semibold mb-4">Shopping Cart</h1>
        {cart.cartItems.length === 0 ? (
          <div key={cart.id} className=" cart-empty">
            <p>Your cart is currently empty</p>
            <div className="start-shopping">
              <Link to="/">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                </svg>{" "}
                <span>See Events</span>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="md:w-3/4">
                <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                  <table className="w-full ">
                    <thead>
                      <tr>
                        <th className="text-left font-semibold">Title</th>
                        <th className="text-left font-semibold">Class</th>
                        <th className="text-left font-semibold">Price</th>
                        <th className="text-left font-semibold ">Quantity</th>
                        <th className="text-left font-semibold">Subtotal</th>
                      </tr>
                    </thead>
                    {cart.cartItems?.map((cartItem, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="py-4">
                            <div className="flex items-center">
                              <img
                                className="h-16 w-16 mr-4"
                                src={
                                  imageBaseUrl + cartItem.imgs[0].img
                                  // card5
                                }
                                alt="Product image"
                              />
                              <span className="font-semibold md:text-[14px] text-[10px]">
                                {cartItem.title}
                              </span>
                            </div>
                          </td>
                          <td className="py-4 md:text-[14px] text-[10px]">
                            {cartItem.name}
                          </td>
                          <td className="py-4 md:text-[14px] text-[10px]">
                            {cartItem.price}
                          </td>
                          <td className="py-4">
                            <div className="flex items-center">
                              <button
                                onClick={() => handleDecreaseCart(cartItem)}
                                className="border rounded-md md:py-2 py-0.5 md:px-4 px-2 md:mr-2 mr-0.5"
                              >
                                -
                              </button>
                              <span className="text-center md:w-8 w-4">
                                {cartItem.qty}
                              </span>
                              <button
                                onClick={() =>
                                  handleIncreasedQuantity(cartItem)
                                }
                                className="border rounded-md md:py-2 py-0.5 md:px-4 px-2 ml-2 "
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <td className="md:py-4 md:text-[14px] text-[10px]">
                            ₦{cartItem.price * cartItem.qty}
                          </td>
                          <button
                            onClick={() => handleRemoveFromCart(cartItem)}
                            className="md:py-4 md:text-[14px] mt-4 text-secondary text-[10px]"
                          >
                            remove{" "}
                          </button>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
                <button
                  className="border rounded-md py-2 px-4 bg-secondary text-white"
                  onClick={() => handleClearcart()}
                >
                  clear cart
                </button>
              </div>
              <div className="md:w-1/4">
                <div className="bg-white rounded-lg shadow-md p-6">
                  <h2 className="text-lg font-semibold mb-4">Summary</h2>
                  <div className="flex justify-between mb-2">
                    <span>Subtotal</span>
                    <span>₦{cart.cartTotalAmount}</span>
                  </div>
                  {/* <div className="flex justify-between mb-2">
                    <span>Taxes</span>
                    <span>$1.99</span>
                  </div> */}
                  {/* <div className="flex justify-between mb-2">
                    <span>Shipping</span>
                    <span>$0.00</span>
                  </div> */}
                  <hr className="my-2" />
                  <div className="flex justify-between mb-2">
                    <span className="font-semibold">Total</span>
                    <span className="font-semibold">
                      {" "}
                      ₦{cart.cartTotalAmount}
                    </span>
                  </div>
                  <Link to="/payments">
                    <button className="bg-secondary text-white py-2 px-4 rounded-lg mt-4 w-full">
                      Checkout
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
