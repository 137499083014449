import React, { useState } from 'react';
import { FlutterWave, paystack } from '../assets';

const PaymentForm = () => {
  const [selectedPayment, setSelectedPayment] = useState('Paystack');

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };
  return (
    <section className="flex items-center justify-center lg:mt-[12px] md:mt-[-50px] mt-4 w-screen min-h-screen bg-gray-100 text-gray-800 p-8">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 w-full max-w-screen-lg">
        <div className="lg:col-span-2">
          <h2 className="text-sm font-medium">Payment Method</h2>
          <div className="bg-white rounded mt-4 shadow-lg ">
            <div className="flex items-center px-8 py-3   width-[50%]">
              <input
                className="appearance-none hidden w-4 h-4 rounded-full border-2 border-white ring-2 ring-blue-600 ring-opacity-100"
                type="radio"
                id="paystack"
                value="Paystack"
                checked={selectedPayment === 'Paystack'}
                onChange={handlePaymentChange}
              />
              <label className="text-sm font-medium ml-4" htmlFor="paystack">
                <img src={paystack} alt="" className=" w-44" />{' '}
              </label>
            </div>

            <div className="flex items-center mt-12 px-8 py-3 border-t ">
              <input
                className="appearance-none hidden w-6 h-6 rounded-full border-2 border-white ring-2 ring-blue-600 ring-opacity-100"
                type="radio"
                id="flutterwave"
                value="Flutterwave"
                checked={selectedPayment === 'Flutterwave'}
                onChange={handlePaymentChange}
              />
              <label className="text-sm font-medium ml-4" htmlFor="flutterwave">
                <img src={FlutterWave} alt="" className=" w-44" />{' '}
              </label>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-sm font-medium">Purchase Summary</h2>
          <div className="bg-white rounded mt-4 shadow-lg py-6">
            <div className="px-8">
              <div className="flex items-end">
                <div
                  className="text-sm font-medium focus:outline-none -ml-1"
                  name=""
                  id=""
                >
                  <span value="">Event </span>
                  {/* <option value="">Product (Billed Annually)</option> */}
                </div>
                <span className="text-sm ml-auto font-semibold">₦600000</span>
                {/* <span className="text-xs text-gray-500 mb-px">/mo</span> */}
              </div>
              {/* <span className="text-xs text-gray-500 mt-2">
                Save 20% with annual billing
              </span> */}
            </div>
            <div className="px-8 mt-4">
              <div className="flex items-end justify-between">
                <span className="text-sm font-semibold">Vat</span>
                <span className="text-sm text-gray-500 mb-px">₦0.75</span>
              </div>
            </div>
            <div className="px-8 mt-4 border-t pt-4">
              <div className="flex items-end justify-between">
                <span className="font-semibold">Pay </span>
                <span className="font-semibold">₦600000</span>
              </div>
              {/* <span className="text-xs text-gray-500 mt-2">
                After 1 month free: $22/mo.
              </span> */}
            </div>
            {/* <div className="flex items-center px-8 mt-8">
              <input id="termsConditions" type="checkbox" />
              <label className="text-xs text-gray-500 ml-2" for="termsConditions">
                I agree to the terms and conditions.
              </label>
            </div> */}
            <div className="flex flex-col px-8 pt-4">
              <button className="flex items-center justify-center bg-secondary text-sm font-medium w-full h-10 rounded text-background hover:bg-blue-700">
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentForm;
