import React, { useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegCalendarCheck } from "react-icons/fa";

import Timer from "./Timer";
import { card5 } from "../assets";
import DetailsTable from "./DetailsTable";
import FeaturedProduct from "./FeaturedProduct";
import { Link, useParams } from "react-router-dom";
import { getSingleEvent } from "../services/ticket";
import { useQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { detailsFetch } from "../feature/detailsSlice";
import moment from "moment";

const imageBaseUrl =
  "https://moloyal.com/test/mosave/adminscript/api/ticket_images/";

const EventDetails = () => {
  const { data, status } = useSelector((state) => state.details);
  const eventId = useParams();
  const dispatch = useDispatch();

  console.log(eventId);
  // console.log(eventId.id);

  // const { data } = useQuery({
  //   queryFn: () => getSingleEvent(eventId),

  //   queryKey: ['details', eventId],

  //   onSuccess: (data) => {
  //     console.log(data);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },

  // });
  const fetchTicket = () => {
    dispatch(detailsFetch(eventId.id));
  };

  useEffect(() => {
    if (eventId.id) {
      fetchTicket();
    }
  }, [dispatch]);
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="container bg-white dark:bg-gray-900 mt-20">
        <div className="grid max-w-screen-xl lg:px-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7 p-4">
            <img
              alt={data.imgs[0].name}
              className="lg:w-full lg:h-[521px] object-contain object-cover rounded border border-gray-200"
              src={imageBaseUrl + data.imgs[0].img}
            />
          </div>
          <div className="lg:mt-0 lg:col-span-5 p-4">
            <h1 className="text-gray-900 font-poppins text-2xl title-font pb-2 font-medium mb-1">
              {data.title}
            </h1>
            <p className="leading-relaxed flex py-2 font-poppins flex-row ">
              <FaLocationDot className="mr-4 mt-1" /> {data.venue}
            </p>
            <p className="leading-relaxed font-poppins flex flex-row py-2">
              <FaRegCalendarCheck className="mr-4 mt-1" />
              {
                moment(data.from_date).format("DD MMMM, YYYY.") +
                  " " +
                  // moment(
                  data.from_time
                // ).format("HH:mm:ss")
              }{" "}
            </p>
            <p className="font-semibold font-poppins">Description:</p>
            <p
              dangerouslySetInnerHTML={{ __html: data.des }}
              className="leading-relaxed font-poppins font-normal flex flex-row text-justify"
            ></p>
            ​
            <DetailsTable
              ticketCategories={data.ticketCategories}
              eventId={eventId.id}
              data={data}
            />
          </div>
        </div>
      </section>

      <section className="text-gray-700 body-font mt-8 overflow-hidden bg-white">
        <div className="container px-5 py-5 mx-auto">
          <FeaturedProduct />
        </div>
      </section>
    </>
  );
};

export default EventDetails;
