// store.js
import { configureStore } from '@reduxjs/toolkit';
import eventsReducer, { eventsFetch } from '../feature/eventSlice';
import cartReducer, { getTotal } from '../feature/cartSlice';
import detailsReducer, { detailsFetch } from '../feature/detailsSlice';

const store = configureStore({
  reducer: {
    events: eventsReducer,
    cart: cartReducer,
    details: detailsReducer,
  },
});

store.dispatch(getTotal());
store.dispatch(eventsFetch());
store.dispatch(detailsFetch());

export default store;
