import axios from "axios";

export const getAllEvents = async () => {
  try {
    const { data } = await axios.get("http://localhost:1109/api/v1/tickets");
    console.log(data);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getSingleEvent = async (eventId) => {
  console.log(eventId);
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/ticket/${eventId}`
    );
    console.log(data);
    return data.data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};
