import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addToCart as addToCart2 } from "../feature/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineShoppingCart } from "react-icons/hi2";

const DetailsTable = (props) => {
  const { ticketCategories, eventId, data } = props;
  const [count, setCount] = useState(1);
  const navigate = useNavigate(); // Get the router's history object
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  console.log(cart);

  const [categories, setCategories] = useState(
    ticketCategories.map((category) => ({ ...category, qty: 1 }))
  );
  // const events = useSelector((state) => state.events);

  const handleAddToCart = (event) => {
    console.log(event);
    const cartItem = {
      imgs: data.imgs,
      title: data.title,
      sn: data.sn,
    };
    const newEvent = { ...event, ...cartItem };
    dispatch(addToCart2(newEvent));
    // navigate('/cart');
  };

  const increment = (index) => {
    const updatedCategories = [...categories];
    updatedCategories[index].qty += 1;
    setCategories(updatedCategories);
  };

  const decrement = (index) => {
    const updatedCategories = [...categories];
    if (updatedCategories[index].qty > 1) {
      updatedCategories[index].qty -= 1;
      setCategories(updatedCategories);
    }
  };
  const handleQuantity = (index, e) => {
    const updatedCategories = [...categories];
    updatedCategories[index].qty = Number(e.target.value);
    setCategories(updatedCategories);
  };

  const calculateTotalPrice = (category) => {
    // Multiply the category price by the count
    return category.price * category.qty;
  };

  const addToCart = (category) => {
    const total = calculateTotalPrice(category);
    // Create an object with the necessary data to pass to the cart route
    const cartItem = {
      category,
      quantity: category.qty,
      totalPrice: total,
      imgs: data.imgs,
      title: data.title,
    };
    console.log(cartItem);
    // Use the router's history to navigate to the cart route and pass the data
    navigate("/cart", { state: { cartItem } });
  };

  const goToCart = () => {
    navigate("/cart");
  };

  return (
    <>
      <div className="relative mb-4">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {categories.map((category, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="py-1 font-semibold text-gray-900 dark:text-white">
                  {category.name}
                </td>
                <td className="px-4 py-1">
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={() => decrement(index)}
                      className="inline-flex items-center font-poppins justify-center p-1 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                    >
                      <span className="sr-only">Quantity button</span>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 2"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 1h16"
                        />
                      </svg>
                    </button>
                    <div>
                      <input
                        type="number"
                        id="first_product"
                        className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="1"
                        required
                        value={category.qty}
                        onChange={(e) => handleQuantity(index, e)}
                      />
                    </div>
                    <button
                      onClick={() => increment(index)}
                      className="inline-flex items-center justify-center h-6 w-6 p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                    >
                      <span className="sr-only">Quantity button</span>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1v16M1 9h16"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
                <td className="px-4 py-1 font-semibold text-gray-900 dark:text-white">
                  ₦{calculateTotalPrice(category)}
                </td>
                {/* Use an onClick handler to call addToCart with the current category */}
                <td className="px-4 py-1">
                  <button
                    onClick={() => {
                      // addToCart(category);
                      handleAddToCart(category);
                    }}
                    className="flex ml-auto text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-secondary rounded"
                  >
                    {/* Add to cart */}
                    <HiOutlineShoppingCart />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div> */}

        {/* </div> */}
      </div>
      <div className="relative mb-4">
        <button
          type="button"
          disabled={cart.cartItems.length == 0}
          onClick={goToCart}
          className={`${
            cart.cartItems.length == 0
              ? "cursor-not-allowed bg-fadeRed opacity-25 "
              : "bg-secondary"
          }  text-white py-2 px-4 rounded-lg mt-4 w-full`}
        >
          {" "}
          Buy Ticket
        </button>
      </div>
    </>
  );
};

export default DetailsTable;
