import React, { useState } from 'react';

const Filters = () => {
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  // const [isMonthsOpen, setIsMonthsOpen] = useState(false);
  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const toggleCategoriesDropdown = () => {
    setIsCategoriesOpen(!isCategoriesOpen);
  };

  // const toggleMonthsDropdown = () => {
  //   setIsMonthsOpen(!isMonthsOpen);
  // };

  const togglePriceRangeDropdown = () => {
    setIsPriceRangeOpen(!isPriceRangeOpen);
  };

  const toggleLocationDropdown = () => {
    setIsLocationOpen(!isLocationOpen);
  };

  return (
    <form className="md:mt-[-300px] mt-[-150px] md:mb-[300px] mb-[50px]">
      <div className="flex md:mx-8 mt-24 md:mb-8 ">
        <div className="relative">
          <button
            className="flex-shrink-0 z-10 inline-flex items-center md:mx-4 mx-1 md:py-4 px-2 py-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
            type="button"
            onClick={toggleCategoriesDropdown}
          >
            Categories{' '}
            <svg
              className={`w-2.5 h-2.5 ml-2.5 transform ${
                isCategoriesOpen ? 'rotate-180' : 'rotate-0'
              } transition-transform`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {isCategoriesOpen && (
            <div className="z-10 origin-top-right absolute md:right-0 -right-14 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Festival
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Conference
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Seminar
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Executive Meeting
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Webinar
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-primary dark:hover:text-white"
                  >
                    Comedy
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Gala Night
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Musical Show
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Trade Fair
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-primary hover:text-background dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Others{' '}
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="relative">
          {/* <button
            className="flex-shrink-0 z-10 inline-flex items-center md:py-4 px-2 py-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
            type="button"
            onClick={toggleMonthsDropdown}
          >
            Months{' '}
            <svg
              className={`w-2.5 h-2.5 ml-2.5 transform ${
                isMonthsOpen ? 'rotate-180' : 'rotate-0'
              } transition-transform`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {isMonthsOpen && (
            <div className="z-10 origin-top-right absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    January
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    February
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    March
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    April
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    May
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    June
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    July
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    August
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    September
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    October
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    November
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    December
                  </button>
                </li>
                Add more months as needed
              </ul>
            </div>
          )} */}
        </div>

        <div className="md:relative absolute md:mt-0 mt-[-50px] w-full md:mx-4 mx-1">
          <input
            type="search"
            id="search-dropdown"
            className="block  p-6 w-full z-20 md:py-4 px-2 py-2 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-gray-50  border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder="Keyword..."
            required
          />
          <button
            type="submit"
            className="absolute top-0 right-0 md:py-4 px-2 py-2  text-sm font-medium h-full text-white bg-primary rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>

        {/* Repeat the structure for Price Range */}
        <div className="relative ">
          <button
            className="flex-shrink-0 z-10 inline-flex items-center md:mx-4 mx-1 md:py-4 px-2 py-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
            type="button"
            onClick={togglePriceRangeDropdown}
          >
            Price{' '}
            <svg
              className={`w-2.5 h-2.5 ml-2.5 transform ${
                isPriceRangeOpen ? 'rotate-180' : 'rotate-0'
              } transition-transform`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {isPriceRangeOpen && (
            <div className="z-10 origin-top-right absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦0 - ₦1000
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦2000 - ₦10000
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦10000 - ₦50000
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦50000 - ₦500000
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦500000 - ₦10000000
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    ₦10000000 - ₦100000000
                  </button>
                </li>

                {/* Add more price range options as needed */}
              </ul>
            </div>
          )}
        </div>

        {/* Repeat the structure for Location */}
        <div className="relative ">
          <button
            className="flex-shrink-0 z-10 inline-flex items-center md:mx-4 mx-1 md:py-4 px-2 py-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
            type="button"
            onClick={toggleLocationDropdown}
          >
            Location{' '}
            <svg
              className={`w-2.5 h-2.5 ml-2.5 transform ${
                isLocationOpen ? 'rotate-180' : 'rotate-0'
              } transition-transform`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {isLocationOpen && (
            <div className="z-10 origin-top-right absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Lagos
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Abuja
                  </button>
                </li>
                {/* Add more location options as needed */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default Filters;
