import React from "react";
import { card1, card2, card3, card4, card5 } from "../assets";
import { Link } from "react-router-dom";
import { FaCartShopping, FaLocationDot } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../feature/cartSlice";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { HiMiniCalendarDays } from "react-icons/hi2";
const CardSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageBaseUrl =
    "https://moloyal.com/test/mosave/adminscript/api/ticket_images/";

  const events = useSelector((state) => state.events);

  const handleAddToCart = (event) => {
    console.log(event);

    dispatch(addToCart(event));
    navigate("/cart");
  };
  console.log(events);

  const PriceSelection = ({ ticketCategories, currency }) => {
    const prices = ticketCategories.map((category) => category.price);
    //const currency = ticketCategories.map((category) => category.currency);
    const lowestPrice = Math.min(...prices);
    const highestPrice = Math.max(...prices);
    return (
      <span>
        {" "}
        {}
        {lowestPrice == highestPrice
          ? currency + lowestPrice
          : currency + lowestPrice + " - " + currency + highestPrice}
      </span>
    );
  };
  return (
    <>
      {events.items.data && events.items.data.length > 0 && (
        <section className="text-gray-600 body-font ">
          <div className="container px-5 py-5 mx-auto">
            <div className="flex flex-wrap -m-4">
              {events.items.data.map((event, index) => (
                <div key={index} className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div className="w-full">
                      {/* <div className="w-full flex p-2">
                        <div className="p-2 "> */}
                      {/* <img
                            src={card1}
                            alt="author"
                            className="w-10 h-10 rounded-full overflow-hidden"
                          /> */}
                      {/* </div> */}
                      {/* <div className="pl-2 pt-2 ">
                          {/* <p className="font-bold">Vipin Bansal</p>
                        </div> */}
                      {/* </div> */}
                    </div>
                    <Link to={`/details/${event.sn}`}>
                      <img
                        className="lg:h-48 md:h-36 w-full object-cover object-center"
                        src={imageBaseUrl + event.imgs[0].img}
                        // src={card}
                        alt="blog cover"
                      />
                    </Link>

                    <div className="p-4">
                      <div className="flex justify-between">
                        <h2 className="tracking-widest text-sm title-font font-bold text-blue mb-1 uppercase ">
                          <PriceSelection
                            ticketCategories={event.ticketCategories}
                            currency={event.currency}
                          />
                        </h2>
                        <span className="tracking-widest items-center text-sm title-font font-bold text-blue mb-1 inline-flex">
                          <HiMiniCalendarDays className="mr-2" /> {event.date}
                        </span>
                      </div>

                      <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                        {event.title}
                      </h1>
                      <div className="flex items-center space-x-6 justify-between text-ellipsis truncate">
                        <Link to={`/details/${event.sn}`}>
                          <Button className="bg-secondary text-white md:mb-2 lg:mb-0">
                            <p className="inline-flex items-center">
                              Get Ticket{" "}
                              <svg
                                className="w-4 h-4 ml-2 "
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M5 12h14"></path>
                                <path d="M12 5l7 7-7 7"></path>
                              </svg>
                            </p>
                          </Button>
                        </Link>
                        {/* <span
                          onClick={() => handleAddToCart(event)}
                          className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto cursor-pointer leading-none text-sm pr-3 py-1 border-r-2 border-gray-200"
                        >
                          <FaCartShopping className="w-4 h-4 text-secondary" />
                        </span> */}
                        <span className="text-black inline-flex items-center leading-none text-sm">
                          <FaLocationDot className="mr-2" />
                          {event.venue}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CardSection;
