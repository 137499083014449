import React from 'react';

const Howitworks = () => {
  return (
    <section
      className={`flex lg:flex-row flex-col lg:py-24 py-6 lg:-mt-32 md:-mt-[500px] `}
    >
      <div className="  flex-1 flex justify-center lg:ml-44 items-start flex-col">
        <h2
          className={`font-inter font-semibold lg:text-[28px] md:text-[18px] mt-[20px] text-[16px] text-black sm:leading-[45.8px] leading-[30.8px] w-full mb-8 px-2`}
        >
          Creating an event on <span className="text-primary ">Moloyal</span>{' '}
          <br />
        </h2>
        <div
          className={`  font-inter text-justify font-normal text-black2 md:text-[16px] text-[12px] leading-[25.8px] lg:max-w-[470px] md:max-w-[570px] md:-mt-6 mt-[-10px] mb-6 px-2`}
        >
          <h3 className="font-semibold mb-2 text-[20px]">1. GET STARTED </h3>
          <p className="font-normal mb-4 text-[16px]">
            click on the create button on the top right corner
          </p>
        </div>
        <div
          className={`  font-inter text-justify font-normal text-black2 md:text-[16px] text-[12px] leading-[25.8px] lg:max-w-[470px] md:max-w-[570px] md:-mt-6 mt-[-10px] mb-6 px-2`}
        >
          <h3 className="font-semibold mb-2 text-[20px]">
            2. FILL THE FORM ACCORDINGLY{' '}
          </h3>
          <p className="font-normal mb-4 text-[16px]">
            When you click the button, you will be redirected to a form. Please
            fill the form accordingly and all the neccessary information needed
          </p>
        </div>
        <div
          className={`  font-inter text-justify font-normal text-black2 md:text-[16px] text-[12px] leading-[25.8px] lg:max-w-[470px] md:max-w-[570px] md:-mt-6 mt-[-10px] mb-6 px-2`}
        >
          <h3 className="font-semibold mb-2 text-[20px]">3. RESPONSE </h3>
          <p className="font-normal mb-4 text-[16px]">
            After completing the form you will need an address from the support
            team.
          </p>
        </div>
      </div>
      <div className="flex-1  flex justify-center items-start flex-col md:mt-24">
        <h2
          className={`font-inter font-semibold lg:text-[28px] md:text-[18px] mt-[20px] text-[16px] text-black sm:leading-[45.8px] leading-[30.8px] w-full mb-8 px-2`}
        >
          How to get event ticket from{' '}
          <span className="text-primary ">Moloyal</span> <br />
        </h2>
        <div
          className={`font-inter text-justify font-normal text-black2 md:text-[16px] text-[12px] leading-[25.8px] lg:max-w-[470px] md:max-w-[570px] md:-mt-6 mt-[-10px] mb-6 px-2`}
        >
          <h3 className="font-semibold mb-2 text-[20px]">1. FIND TICKETS:</h3>
          <p className="font-normal mb-4 text-[16px]">
            To buy event tickets from Moloyal, find an event that is of interest
            to you, click on it and see more details about the event.
          </p>
          <h3 className="font-semibold mb-2 text-[20px]">
            2. ADD THE TICKET TO CART:
          </h3>
          <p className="font-normal mb-4 text-[16px]">
            From the event details page, you will get more information about the
            event, choose the class of the event, increase or decrease the
            number of tickets that you want and add it to cart.
          </p>
          <h3 className="font-semibold mb-2 text-[20px]">3. MAKE PAYMET:</h3>
          <p className="font-normal mb-4 text-[16px]">
            From the cart page, provide the neccessary information and procceed
            to checkout.
          </p>
          <h3 className="font-semibold mb-2 text-[20px]">
            4. Get CONFIRMATION:
          </h3>
          <p className="font-normal mb-4 text-[16px]">
            After your payment is confirmed, Buyer will will recieve an email
            containing ticket information with a barcode.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Howitworks;
