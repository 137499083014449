import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { card5 } from '../assets';

const ImageSlider = () => {
  return (
    <div
      // autoPlay // Enable auto-play
      // interval={3000} // Set the interval for each slide (in milliseconds)
      // infiniteLoop // Loop the carousel infinitely
      // showThumbs={false} // Hide thumbnail navigation
      // showStatus={false} // Hide slide status indicator
      // showArrows={true} // Hide arrow navigation
      // showIndicators={false} // Hide slide indicators
      // transitionTime={500} // Set the transition time (in milliseconds)
      // emulateTouch // Enable touch swipe for mobile devices
      className="rounded-xl"
    >
      {/* <div>
        <img
          src={card1}
          alt="card"
          className="md:h-[600px] w-full object-cover"
        />
      </div>
      <div>
        <img
          src={card2}
          alt="card"
          className="md:h-[600px] w-full object-cover"
        />
      </div>
      <div>
        <img
          src={card3}
          alt="card"
          className="md:h-[600px] w-full object-cover"
        />
      </div>
      <div>
        <img
          src={card4}
          alt="card"
          className="md:h-[600px] w-full object-cover"
        />
      </div> */}
      <div>
        <img
          src={card5}
          alt="card"
          className="md:h-[600px] w-full object-cover"
        />
      </div>
      {/* Add more <div> elements for additional images */}
    </div>
  );
};

export default ImageSlider;
