import React from 'react';

const Vision = () => {
  return (
    <div className="md:flex md:flex-wrap justify-between lg:mx-32 md:mx-8 md:mt-24 mt-4">
      {/* Card 1 */}
      <div className="md:w-1/3 w-full md:mb-0 mb-4 hover:scale-105 transition-transform">
        <div className="bg-transparent border-none p-4">
          <div className="text-[32px] font-poppins text-black3 font-semibold mb-6 text-primary">
            Our Vision
          </div>
          <p className="text-base text-justify font-poppins text-[20px] text-black3 leading-[30.8px]">
            Our vision is to be the most respected and trusted IT professional
            services firm recognized by our clients for delivering excellence.
          </p>
        </div>
      </div>

      {/* Card 2 */}
      <div className="md:w-1/3 w-full md:mb-0 mb-4 hover:scale-105 transition-transform">
        <div className="bg-transparent border-none p-4">
          <div className="text-[32px] font-poppins text-black3 font-semibold mb-6 text-primary">
            Our Mission
          </div>
          <p className="text-base text-justify font-poppins text-[20px] text-black3 leading-[30.8px] ">
            Our mission is to contribute to the success of our clients by
            partnering with them to enhance their business processes and to
            create innovative solutions to their business challenges.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="md:w-1/3 w-full hover:scale-105 transition-transform">
        <div className="bg-transparent border-none p-4">
          <div className="text-[32px] font-poppins text-black3 font-semibold mb-6 text-primary">
            Our Values
          </div>
          <p className="text-base text-justify font-poppins text-[20px] text-black3 leading-[30.8px] ">
            Honesty, integrity, commitment, and delivery are important to us. We
            are a company that values individuals and teamwork. We continually
            strive as a firm to make a positive and lasting impact.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Vision;
