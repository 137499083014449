import React from "react";
import { card1, card2 } from "../assets";
import { FaLocationDot } from "react-icons/fa6";

const FeaturedProduct = () => {
  return (
    <section className="text-gray-600 body-font mt-5">
      <div className="container px-5 py-5 mx-auto">
        <h1 className="font-poppins font-bold mb-8 ">Related Events</h1>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <div className="w-full">
                <div className="w-full flex p-2">
                  <div className="p-2 ">
                    <img
                      src={card1}
                      alt="author"
                      className="w-10 h-10 rounded-full overflow-hidden"
                    />
                  </div>
                  <div className="pl-2 pt-2 ">
                    <p className="font-bold">Vipin Bansal</p>
                    <p className="text-xs">2 June 2022</p>
                  </div>
                </div>
              </div>

              <img
                className="lg:h-48 md:h-36 w-full object-cover object-center"
                src={card2}
                alt="blog cover"
              />

              <div className="p-4">
                <h2 className="tracking-widest text-xs title-font font-bold text-green-400 mb-1 uppercase ">
                  ₦2000 - ₦1000000
                </h2>
                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                  The words unspoken show
                </h1>
                <div className="flex items-center flex-wrap ">
                  <a
                    href="/details"
                    className="text-green-800  md:mb-2 lg:mb-0"
                  >
                    <p className="inline-flex items-center">
                      Get Ticket{" "}
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </a>
                  <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cart"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />{" "}
                    </svg>
                  </span>
                  <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                    <FaLocationDot className="mr-2" />
                    Abuja
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <div className="w-full">
                <div className="w-full flex p-2">
                  <div className="p-2 ">
                    <img
                      src={card1}
                      alt="author"
                      className="w-10 h-10 rounded-full overflow-hidden"
                    />
                  </div>
                  <div className="pl-2 pt-2 ">
                    <p className="font-bold">Vipin Bansal</p>
                    <p className="text-xs">2 June 2022</p>
                  </div>
                </div>
              </div>

              <img
                className="lg:h-48 md:h-36 w-full object-cover object-center"
                src={card2}
                alt="blog cover"
              />

              <div className="p-4">
                <h2 className="tracking-widest text-xs title-font font-bold text-green-400 mb-1 uppercase ">
                  ₦2000 - ₦1000000
                </h2>
                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                  The words unspoken show
                </h1>
                <div className="flex items-center flex-wrap ">
                  <a
                    href="/details"
                    className="text-green-800  md:mb-2 lg:mb-0"
                  >
                    <p className="inline-flex items-center">
                      Get Ticket{" "}
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </a>
                  <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cart"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />{" "}
                    </svg>
                  </span>
                  <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                    <FaLocationDot className="mr-2" />
                    Abuja
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProduct;
