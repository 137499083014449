import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { getSingleEvent } from "../services/ticket";

const initialState = {
  data: null,
  status: null,
};

export const detailsFetch = createAsyncThunk(
  "details/detailsFetch",
  async (eventId) => {
    try {
      console.log(eventId);
      // const response = await axios.get(
      //   `process.env.BASE_URL/ticket/${eventId}`
      // );

      const response = await getSingleEvent(eventId);
      console.log(response);
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const detailSlice = createSlice({
  name: "details",
  initialState,
  reducers: {},
  // extraReducers: {
  //   [detailsFetch.pending]: (state, action) => {
  //     state.status = 'pending';
  //   },
  //   [detailsFetch.fulfilled]: (state, action) => {
  //     console.log(action);
  //     state.status = 'success';
  //     state.data = action.payload; // Update 'items' to 'data'
  //   },
  //   [detailsFetch.rejected]: (state, action) => {
  //     state.status = 'rejected';
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(detailsFetch.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(detailsFetch.fulfilled, (state, action) => {
      console.log(action);
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(detailsFetch.rejected, (state, action) => {
      state.status = "rejected";
      state.data = null;
    });
  },
});

export default detailSlice.reducer;
